<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>

          <b-col md="3">
            <b-form-group
                label="Kezdő dátum"
            >
              <validation-provider
                  #default="{ errors }"
                  name="kezdő dátum"
                  rules="required"
                  vid="startDate"
              >
                <b-input-group>
                  <b-form-input
                      v-model="formData.startDate"
                      type="text"
                      placeholder="ÉÉÉÉ-HH-NN"
                      autocomplete="off"
                      :formatter="formatDateAllowedSymbols"
                      show-decade-nav
                      required
                  />

                  <b-input-group-append>
                    <b-form-datepicker
                        v-model="formData.startDate"
                        size="sm"
                        show-decade-nav
                        button-only
                        right
                        :locale="$i18n.locale"
                        v-bind="labels[$i18n.locale] || {}"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Befejező dátum"
            >
              <validation-provider
                  #default="{ errors }"
                  name="befejező dátum"
                  rules="required"
                  vid="endDate"
              >
                <b-input-group>
                  <b-form-input
                      v-model="formData.endDate"
                      type="text"
                      placeholder="ÉÉÉÉ-HH-NN"
                      autocomplete="off"
                      :formatter="formatDateAllowedSymbols"
                      show-decade-nav
                      required
                  />

                  <b-input-group-append>
                    <b-form-datepicker
                        v-model="formData.endDate"
                        size="sm"
                        show-decade-nav
                        button-only
                        right
                        :locale="$i18n.locale"
                        v-bind="labels[$i18n.locale] || {}"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Nyitás"
            >
              <validation-provider
                  #default="{ errors }"
                  name="nyitás"
                  rules="required"
                  vid="opening"
              >
                <b-input-group>
                  <b-form-input
                      v-model="formData.opening"
                      type="time"
                      :state="errors.length > 0 ? false:null"
                      :formatter="formatTimeAllowedSymbols"
                  />

                  <b-input-group-append>
                    <b-form-timepicker
                        v-model="formData.opening"
                        size="sm"
                        button-only
                        right
                        :locale="$i18n.locale"
                        v-bind="labels[$i18n.locale] || {}"
                    />
                  </b-input-group-append>

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Zárás"
            >
              <validation-provider
                  #default="{ errors }"
                  name="zárás"
                  rules="required"
                  vid="closing"
              >
                <b-input-group>
                  <b-form-input
                      v-model="formData.closing"
                      type="time"
                      :locale="$i18n.locale"
                      :state="errors.length > 0 ? false:null"
                      :formatter="formatTimeAllowedSymbols"
                  />

                  <b-input-group-append>
                    <b-form-timepicker
                        v-model="formData.closing"
                        size="sm"
                        button-only
                        right
                        :locale="$i18n.locale"
                        v-bind="labels[$i18n.locale] || {}"
                    />
                  </b-input-group-append>

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import labels from '@/libs/datepicker/labels'
import {formatDateAllowedSymbols, formatTimeAllowedSymbols} from "@core/utils/filter";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTimepicker,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'reservation',
      formData: {
        startDate: '',
        endDate: '',
        opening: '',
        closing: '',
      },
      labels,
      formatDateAllowedSymbols,
      formatTimeAllowedSymbols,
      required,
    }
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('storeReservationDayInterval', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'reservation-day-intervals'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
